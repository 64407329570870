.overview-stats {
  display: flex;
  flex-direction: column;
  padding: 29px 33px 29px 33px;
}

.section-divider {
  margin-top: 30px;
  margin-bottom: 30px;
  width: 100%;
}

.stats-section {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
}

.stats-title {
  font-size: 20px;
  line-height: 1.3;
  color: #222222;
  text-align: center;
  margin-bottom: 30px;
}

.stats-wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.stats-label {
  font-size: 12px;
  line-height: 1.3;
  font-weight: 600;
  color: #222222;
  margin-top: 6px;
  margin-bottom: 6px;
}

.progress {
  position: relative;
  background-color: rgba(145, 158, 171, 0.25);
  border-radius: 14px;
  padding: 3px 10px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.progress-label {
  color: white;
  position: relative;
  font-size: 12px;
  font-weight: 600;
}

.progress-bar {
  border-radius: 14px;
  background-color: #3dc1d3;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
}
