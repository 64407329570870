.consult-container {
  background-image: url("../../assets/consult/consult-background.png");
  display: flex;
  flex-direction: column;
  background-color: #f0e9e4;
  min-height: 100vh;
  background-size: 100vw 135vh;
}

.column-centered {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
}

.createPasswordForm .Polaris-Button.Polaris-Button {
  border-radius: 10px;
  background: rgba(145, 158, 171, 0);
  border: none;
  box-shadow: none;
  padding: 7px 7px;
  margin-right: 1em;
}

.createPasswordForm .Polaris-Button.Polaris-Button:hover {
  background: rgba(145, 158, 171, 0) !important;
}