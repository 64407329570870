.inbox-column-name {
  padding: 0;
  margin-top: 20px;
  margin-bottom: 20px;
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 21px;
  display: flex;
  align-items: center;
  letter-spacing: 0.06em;
  color: #000000;
}

.inbox-column-name-narrow {
  font-size: 12px;
}

.go-to-convo-icon {
  width: 30px;
  height: 30px;
}

.rdt_TableRow {
  cursor: pointer;
  min-height: 54px;
}

.rdt_TableHeadRow {
  padding: 0;
  padding-top: 20px;
}

.last-message-row {
  overflow: hidden;
  word-wrap: break-word;
  text-overflow: ellipsis;
  white-space: pre;
  max-height: 15px;
  line-height: 15px;
  max-width: 50vw;
}

.row-mentioned {
  width: 90px;
  background-color: #8d2995;
  color: #ffffff;
  font-weight: 800;
  border-radius: 20px;
  margin-top: auto;
  margin-bottom: auto;
  padding-right: 8px;
  padding-left: 8px;
  margin-left: 15px;
  padding-top: 3px;
  padding-bottom: 3px;
  text-align: center;
}

.Modal {
  color: var(--color-primary);
  z-index: 100;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  background: var(--color-background);
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.25);
  animation: 0.3s cubic-bezier(0.22, 0.61, 0.36, 1) 1 slideIn;
  padding: 0;
  width: 100%;
  background: white;
  max-width: 700px;
}

.modal-conversation {
  padding: 0px 30px 00px 30px;
  height: 100%;
}

.modal-conversation-mobile {
  z-index: 100;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  margin-top: 40px;
  width: 100%;
}

.client-link {
  color: #2562d8;
}

.included {
  color: #000;
}

.inbox-column-icon {
  width: 30px;
  height: 30px;
}

.inbox-column-type {
  height: 100%;
  width: 15px;
}

.manablue-indicator {
  background-color: #2562d8;
}

.purple-indicator {
  background-color: #8d2995;
}

.empty-conversation-list {
  width: 100%;
  padding: 24px;
  text-align: center;
  font-size: 16px;
  font-family: Helvetica, sans-serif;
  color: #4f4f4f;
}

.done-check-button {
  background-color: #f4d35e;
  width: 34px;
  height: 34px;
  border-radius: 34px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.cc-icon {
  font-weight: bold;
}

.external-icon {
  color: #2562D8;
}

.internal-icon {
  color: #8D2995;
}