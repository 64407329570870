.data-table-header {
  display: flex;
  flex-direction: row;
  background: #ffffff;
  padding: 50px 70px 20px 70px;
  justify-content: space-between;
  align-items: center;
}
.data-table-title {
  font-size: 34px;
  font-weight: 600;
  color: #000000;
}
.data-table-actions {
  background-color: #ffffff;
  padding-bottom: 30px;
}
.data-table-actions-content {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-top: 10px;
}
.selected-count {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #2f7ac8;
  border-radius: 50%;
  width: 33px;
  height: 33px;
  color: #ffffff;
  font: 18px;
  font-weight: 800;
  text-align: center;
}
.selected-label {
  margin-left: 15px;
  margin-right: 15px;
  color: #797979;
  font-size: 18px;
}
.selected-button {
  color: #ffffff;
  background-color: #2f7ac8;
}
.data-table-content {
  margin-top: 4px;
  background: white;
  border: none;
  position: relative;
}
.rdt_TableHeadRow .rdt_TableCol div {
  font-size: 14px;
  font-weight: 600;
  color: #131313;
}
.rdt_TableRow:hover {
  background-color: #cbddf1 !important;
}
.rdt_TableHeadRow {
  padding-right: 40px;
}
