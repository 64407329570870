.GoalManagement {
  padding: 20px;
  overflow: auto;
}

.GoalManagement-title {
  font-size: 21px;
  line-height: 1.33;
  color: #000000;
  margin-bottom: 20px;
}
