.provider-matching-container {
  display: flex;
  flex-direction: column;
  padding: 20px;
  height: 100%;
}

.provider-list {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 20px;
  background: #f9f9f9;
  border-radius: 12px;
  overflow-y: scroll;
}

.provider-list input {
  box-sizing: border-box;

  width: 24px;
  height: 24px;

  background: #ffffff;
  border: 1px solid #3dc1d3;
  border-radius: 4px;
  margin-right: 10px;
}

li {
  margin: 5px;

  width: 462px;
  height: 24px;

  font-family: "Helvetica";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;

  color: #000000;

  flex: none;
  order: 1;
  align-self: stretch;
}

.selected-providers {
  margin-top: 15px;
}

strong {
  color: #3dc1d3;
}

.intake-link {
  margin-top: 15px;
}

.intake-link p {
  display: block;
  word-break: break-all;
  margin-bottom: 5px;
  height: 70px;
  background: #f9f9f9;
  border-radius: 12px;
  padding: 10px;
  margin: 10px 0px;
}

hr {
  background-color: #e0e0e0;
  border: none;
  height: 1px;
  width: 100%;
}

.unsaved-changes {
  color: red;
  margin-bottom: 15px;
}

#schedule-intake-link-text {
  background-color: rgb(249, 249, 249);
  border-radius: 12px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: start;
  margin-bottom: 15px;
}

#schedule-intake-link-text h1 {
  padding: 10px;
}

#schedule-intake-link-text * {
  position: relative;
}
