.column-centered {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.loading-container {
  padding-bottom: 15vh;
}

.content-container {
  padding: 50px;
  margin: 30px;
  padding-top: 80px;
  padding-bottom: 80px;
  background-color: #ffffff;
  font-weight: 600;
  font-size: 26px;
  line-height: 40px;
  text-align: center;
}

.header {
  background-color: #e5eeff;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  padding: 15px;
  padding-left: 20px;
  position: sticky;
  top: 0;
  z-index: 100;
}

.form-name {
  color: #2562d8;
  font-weight: 800;
  font-size: 14px;
}

.form-user {
  color: #383b43;
  font-weight: 500;
  font-size: 14px;
}

.message-title {
  font-weight: 600;
  font-size: 26px;
  line-height: 40px;
  text-align: center;
}
