.Chrome {
  display: flex;
  flex-direction: row;
}

.Chrome-content {
  position: relative;
  flex: 1;
  min-width: 0px;
}

.Chrome-content .Spinner {
  top: 70px;
}
