.Polaris-Filters__FiltersWrapper.Polaris-Filters__FiltersWrapperWithAddButton {
  border-bottom: none !important;
}

.filters,
.Polaris-Popover__Section {
  margin: 10px;
  width: 500px;

  .Polaris-Text {
    color: black;
  }

  .Polaris-TextField__Input.Polaris-TextField__Input::placeholder {
    color: black;
    opacity: 1;
  }

  .Polaris-Button.Polaris-Button {
    padding: 0px;
  }

  .Polaris-Text--root.Polaris-Text--root {
    color: black;
    font-size: 14px;
    font-weight: 600;
  }
}
