.Invitee-Goals-Page {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: var(--spacing-s);
  box-sizing: border-box;
  background: rgb(244, 245, 250);
  background-image: url("../assets/login-bg.png");
  background-position: center bottom;
  background-size: 80%;
  background-repeat: no-repeat;
}

.Invitee-Goals-Container {
  width: 400px;
  height: 600px;
  background: white;
  border-radius: 4px;
}
