.Register {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: var(--spacing-s);
  box-sizing: border-box;
  background: rgb(244, 245, 250);
  background-image: url("../assets/login-bg.png");
  background-position: center bottom;
  background-size: 80%;
  background-repeat: no-repeat;
}

.Manatee-logo {
  background: url("../assets/manatee-logo.png");
  background-position: center center;
  background-size: contain;
  width: 280px;
  height: 280px;
  margin: 0 auto 10px auto;
}

.Main-form {
  width: 380px;
  background: white;
  border-radius: 4px;
  padding: 40px;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.1);
}

.Register-label {
  font-size: 20px;
  display: block;
  margin-bottom: 10px;
}

.Register-password {
  font-size: 20px;
  padding: 8px 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.Register h1 {
  font-size: 44px;
  font-weight: 600;
  text-align: center;
  line-height: 1.1;
  color: #000000;
  margin-bottom: 80px;
}

.Register-forgot {
  text-align: center;
  font-size: 14px;
  line-height: 1.4;
  color: #919eab;
  margin-top: 20px;
}

.Register-forgot a {
  color: #007ace;
}

.Register .Polaris-TextField__Input.Polaris-TextField__Input {
  text-align: center;
}

.Register .Polaris-TextField.Polaris-TextField {
  height: 48px;
}

.Register .Polaris-Button.Polaris-Button {
  height: 48px;
}
