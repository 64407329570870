.form-container {
  display: flex;
  flex: 1;
  flex-direction: row;
  height: 100%;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  padding: 20px;
  padding-bottom: 100px;
}

.radio-group .radio-wrapper {
  padding-right: 15px;
  padding-top: 5px;
  padding-bottom: 5px;
  min-width: 50px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
}

.radio-wrapper .input-radio {
  margin-right: 5px;
}
