.Polaris-TextField.Polaris-TextField {
  border-radius: 12px;
  background-color: #f4f6f8;
}
.Polaris-TextField__Backdrop.Polaris-TextField__Backdrop {
  border-radius: 12px;
  background-color: #f4f6f8;
  box-shadow: none;
  border: none;
}
.Polaris-Select.Polaris-Select {
  border-radius: 12px;
  background-color: #f4f6f8;
  text-align: center;
}
.Polaris-Select__Backdrop.Polaris-Select__Backdrop {
  display: none;
}

.Polaris-TextField__Input.Polaris-TextField__Input::placeholder {
  color: #919eab;
  opacity: 1;
}

.Polaris-DataTable__Cell.Polaris-DataTable__Cell {
  font-size: 14px;
  vertical-align: middle;
  padding-top: 20px;
  padding-bottom: 20px;
}

.Polaris-DataTable__Cell--header.Polaris-DataTable__Cell--header {
  border-top: none;
  border-bottom-color: #ececec;
  padding: 15px;
  font-weight: 600;
  font-size: 14px;
  line-height: normal;
  vertical-align: middle;
}

.Polaris-DataTable__TableRow.Polaris-DataTable__TableRow {
  cursor: pointer;
}

.Polaris-Button.Polaris-Button {
  border-radius: 10px;
  background: rgba(145, 158, 171, 0.25);
  border: none;
  box-shadow: none;
  padding: 7px 20px;
}

.Polaris-Button.Polaris-Button:hover {
  background: rgba(145, 158, 171, 0.25) !important;
}

.Polaris-Text--root.Polaris-Text--root {
  color: #919eab;
  font-size: 14px;
  font-weight: 600;
}

.Polaris-Button--variantPrimary.Polaris-Button--variantPrimary {
  background: rgba(0, 122, 206, 0.25);
}

.Polaris-Button--variantPrimary.Polaris-Button--variantPrimary .Polaris-Text--root {
  color: #007ace;
}

.Polaris-Button--plain.Polaris-Button--plain,
.Polaris-Button--plain.Polaris-Button--plain:hover {
  background: transparent !important;
}

.Polaris-Card.Polaris-Card {
  display: flex;
  flex-direction: column;
  height: 100%;
  border-radius: 0px;
}

.Polaris-Card__Section.Polaris-Card__Section {
  padding: 20px 40px 50px 40px;
}

.Polaris-Heading.Polaris-Heading {
  font-size: 26px;
  font-weight: normal;
  line-height: 1.2;
  color: #131313;
}

.Polaris-Card__Header.Polaris-Card__Header {
  padding: 20px;
  border-bottom: 1px solid #ececec;
}

.Polaris-BlockStack__Item.Polaris-BlockStack__Item {
  margin-top: 10px;
}

.Polaris-TextField__Prefix svg {
  fill: none;
}

.Polaris-TextField__ClearButton {
  margin: 0 5px 0 0;
  padding: 0;
  position: absolute;
  right: 0;
  background: #f4f6f8;
}
