.TwoFactorAuthSubmitCode {
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: var(--spacing-s);
    box-sizing: border-box;
    background: rgb(244, 245, 250);
    background-image: url("../assets/login-bg.png");
    background-position: center bottom;
    background-size: 80%;
    background-repeat: no-repeat;
  }
  
  .TwoFactorAuthSubmitCode-logo {
    background: url("../assets/manatee-logo.png");
    background-position: center center;
    background-size: contain;
    width: 280px;
    height: 280px;
    margin: 0 auto 10px auto;
  }
  
  .TwoFactorAuthSubmitCode-text {
    text-align: center;
    font-size: 14px;
    line-height: 1.4;
    color: #575f67;
    margin-top: 20px;
    margin-bottom: 25px;
    font-weight: 600;
  }

  .TwoFactorAuthSubmitCode-form {
    width: 380px;
    background: white;
    border-radius: 4px;
    padding: 40px;
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.1);
  }
  
  .TwoFactorAuthSubmitCode-label {
    font-size: 20px;
    display: block;
    margin-bottom: 10px;
  }
  
  .TwoFactorAuthSubmitCode-password {
    font-size: 20px;
    padding: 8px 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .TwoFactorAuthSubmitCode h1 {
    font-size: 44px;
    font-weight: 600;
    text-align: center;
    line-height: 1.1;
    color: #000000;
    margin-bottom: 80px;
  }
  
  .TwoFactorAuthSubmitCode-forgot {
    text-align: center;
    font-size: 14px;
    line-height: 1.4;
    color: #919eab;
    margin-top: 20px;
  }
  
  .TwoFactorAuthSubmitCode-forgot a {
    color: #007ace;
  }
  
  .TwoFactorAuthSubmitCode .Polaris-TextField__Input.Polaris-TextField__Input {
    text-align: center;
  }
  
  .TwoFactorAuthSubmitCode .Polaris-TextField.Polaris-TextField {
    height: 48px;
  }
  
  .TwoFactorAuthSubmitCode .Polaris-Button.Polaris-Button {
    height: 48px;
  }
  