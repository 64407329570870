@import "styles/media.css";

.Patient {
  display: flex;
  flex-direction: column;
  height: 100%;
  max-height: 100vh;
}

.Patient-header {
  display: flex;
  flex-direction: row;
  background: #007ace;
  height: 100px;
  padding: 35px 30px 39px;
  justify-content: space-between;
  align-items: center;
}

.Patient-title {
  font-size: 32px;
  font-weight: 600;
  color: #131313;
}

.Column {
  display: flex;
}

.Patient-name {
  padding-top: 20px;
  padding-right: 20px;
  color: white;
}

.Patient-username {
  color: white;
  font-size: 22px;
}

.Patient-content {
  border: none;
  flex-grow: 1;
  overflow-y: auto;
  overflow-x: hidden;
}

.Patient-statsCard {
  grid-area: a;
  height: 100%;
}

.Patient-chatCard {
  grid-area: c;
}

.dayPicker {
  position: absolute;
  right: 0;
  top: -5px;
}

.noElements,
.noPoints {
  width: 100%;
  text-align: center;
  font-weight: bold;
  padding: 50px 0;
}

.statLabel-wrapper {
  height: 100%;
}

.Progress {
  position: relative;
  background-color: rgba(145, 158, 171, 0.25);
  border-radius: 14px;
  padding: 3px 10px;
  margin-top: 10px;
  margin-bottom: 20px;
}

.Progress-label {
  color: white;
  position: relative;
  font-size: 12px;
  font-weight: 600;
}

.Progress-bar {
  border-radius: 14px;
  background-color: #3dc1d3;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
}

.Patient .Polaris-Select {
  min-width: 110px;
}

@keyframes slideIn {
  from {
    opacity: 0;
    transform: translateX(100px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.Modal {
  color: var(--color-primary);
  z-index: 100;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  background: var(--color-background);
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.25);
  animation: 0.3s cubic-bezier(0.22, 0.61, 0.36, 1) 1 slideIn;
  padding: 0;
  width: 100%;
  background: white;
  width: 45vw;
  max-width: 540px;
}

.Modal-body {
  height: 100%;
}

.Modal-overlay {
  position: fixed;
  z-index: 90;
  background: rgba(0, 0, 0, 0.6);
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  animation: 0.3s cubic-bezier(0.22, 0.61, 0.36, 1) 1 fadeIn;
}

.Modal-close {
  font-size: 24px;
  padding: 4px;
  position: absolute;
  top: 24px;
  right: 24px;
  line-height: 14px;
}

.Patient .Modal .Polaris-Card__Header .Polaris-Button__Content {
  font-size: 32px;
  font-weight: 300;
}

.Patient .Modal .Polaris-Card {
  border: none;
  box-shadow: none;
  display: flex;
  flex-direction: column;
  height: 100%;
}

.Patient-toolSeparator {
  width: 1px;
  background: #ececec;
  height: 60px;
  margin: 0 20px 0 30px;
}

.Patient-bell {
  position: relative;
  cursor: pointer;
}

.Patient-bellCount {
  background: #de3618;
  border-radius: 30px;
  padding: 3px;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  font-weight: 500;
  color: #ffffff;
  position: absolute;
  top: -10px;
  right: -10px;
}

.Polaris-Button__Content.Polaris-Button__Content {
  color: #131313;
}

.patient-tools {
  display: flex;
  width: 300px;
  justify-content: space-around;
}
