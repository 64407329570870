@import "styles/media.css";

.MyInbox {
  display: flex;
  flex-direction: column;
}

.inbox-selection-container {
  flex: 1.65;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.inbox-custom-polaris-text-field {
  flex: 0.8;
  margin-left: 10px;
  max-width: 245px !important;
  min-width: 125px !important;
}

.inbox-custom-polaris-text-field-narrow {
  margin: 20px;
}

.inbox-custom-polaris-text-field .Polaris-TextField__Input {
  background-color: #e0e0e0 !important;
}

::placeholder {
  color: #999999 !important;
}

.view-inbox-label {
  font-family: "Helvetica";
  font-weight: 700;
  font-size: 14px;
  letter-spacing: 0.06em;
  margin-left: 40px;
  margin-right: 15px;
  color: #000000;
}

.inbox-custom-polaris .Polaris-Select__Input {
}

.inbox-custom-polaris .Polaris-Select__Content {
  background: #c9d3e0;
  border-radius: 14px;
  padding: 8px 20px 8px 20px;

  font-family: "Helvetica";

  font-weight: 700;
  font-size: 14px;

  color: #000000;
  min-width: 175px;
}
