.container {
  flex: 1;
  padding: 4px;
}
.imageContainer {
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.imageContainerSmall {
  width: 100%;
}
.imageContainerLarge {
  width: 50%;
  padding: 12px;
}
.image {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
