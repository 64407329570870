.GoalCard-header {
  height: 40px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.GoalCard-title {
  font-family: Avenir Next;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 33px;
  display: flex;
  align-items: center;

  color: #000000;
}
.GoalCard-switch {
  width: 300px;
  margin-left: 15px;
}
