.new-convo-modal-content {
  width: 566px;
}

.new-convo-title-text {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;

  font-weight: 700;
  font-size: 30px;
  font-family: Helvetica, sans-serif;
}

.new-convo-header-text {
  font-weight: 700;
  font-size: 24px;
  font-family: Helvetica, sans-serif;
  padding-top: 34px;
  padding-bottom: 17px;
  border-bottom: 1px solid #cbcbcb;

  display: flex;
  flex-direction: row;
  align-items: center;
}

.new-convo-header-icon {
  width: 30px;
  height: 30px;
  margin-right: 21px;
}

.new-convo-error-text {
  background-color: #f2f2f2;
  color: #ff2132;
  font-weight: 700;
  font-size: 20px;
  font-family: Helvetica, sans-serif;
  line-height: 27px;

  padding: 20px 40px;
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.new-convo-modal-buttons {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 36px;
}

.Polaris-Choice {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 20px;
  border-bottom: 1px solid #cbcbcb;
}

.contact-selection,
.Polaris-Choice__Label {
  font-weight: 400;
  font-size: 24px;
  font-family: Helvetica, sans-serif;
}

.new-convo-close-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
