.Connection {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: var(--spacing-s);
  box-sizing: border-box;
  background: rgb(244, 245, 250);
  background-image: url("../assets/login-bg.png");
  background-position: center bottom;
  background-size: 80%;
  background-repeat: no-repeat;
}

.Connection-form {
  width: 395px;
  background: white;
  border-radius: 4px;
  padding: 40px;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.1);
}

.Connection-header {
  font-size: 40px;
  font-weight: bold;
  text-align: center;
  padding: 20px 50px 20px 50px;
}

.Connection-label {
  text-align: center;
  font-size: 14px;
  line-height: 1.4;
  color: #575f67;
  margin-top: 25px;
  margin-bottom: 25px;
  font-weight: 600;
}

.Connection-label a {
  color: #575f67;
}

#Connection-submit {
  margin-top: 30px;
}

.Connection .Polaris-Button.Polaris-Button {
  height: 48px;
}

.Connection-type-label-selected {
  background-color: #919eab;
  border-radius: 12px;
  font-size: 11px;
  line-height: 1.4;
  color: #ffffff;
  margin-top: 5px;
  margin-bottom: 5px;
}

.Connection-type-label-unselected {
  background-color: #f4f6f8;
  border-radius: 12px;
  font-size: 11px;
  line-height: 1.4;
  color: #76828e;
  margin-top: 5px;
  margin-bottom: 5px;
}

.Connection-type-text {
  padding: 15px;
  font-family: Roboto;
  font-weight: 900;
  display: flex;
  height: 90px;
  min-width: 315px;
  align-items: center;
}

.Connection-text {
  padding-left: 15px;
}

#Weight-bold {
  font-weight: 900;
}

#Weight {
  font-weight: 500;
}

.image {
  height: 40px;
  width: 40px;
}
