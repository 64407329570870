.GoalAssignmentContainer {
  background-color: #ffffff;
  min-width: 450px;
  max-width: 500px;
  width: 100%;
  height: 90vh;
  min-height: 500px;
  max-height: 800px;
  background: white;
  border-radius: 4px;
  padding: 40px;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-family: Helvetica Neue;
}

.GoalAssignment-title {
  font-size: 24px;
  line-height: 160%;
  font-weight: bold;
  color: #000000;
}

.GoalAssignment-subtitle {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 160%;
  color: #575f67;
  padding-bottom: 10px;
}

.GoalAssignment-buttonContainer {
  padding-top: 10px;
  width: 100%;
}

.GoalAssignment-goalContainer {
  overflow: scroll;
  width: 100%;
  text-align: left;
  padding-left: 20px;
  padding-right: 30px;
  border-bottom: 1px solid rgba(145, 158, 171, 0.25);
  border-top: 1px solid rgba(145, 158, 171, 0.25);
}

.GoalAssignment-goalItem {
  display: flex;
  flex-direction: row;
  margin-top: 5px;
  margin-bottom: 5px;
}
