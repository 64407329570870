.bulk-message-modal {
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: #00000088;
  z-index: 21;
}
.modal-container {
  width: 530px;
  height: 650px;
  background-color: #ffffff;
  margin-top: 7vh;
  display: flex;
  flex-direction: column;
  padding: 22px;
  box-shadow: 4px 4px 23px -6px rgba(0, 0, 0, 0.25);
}
.template-modal-container {
  position: relative;
  width: 530px;
  margin-top: 7vh;
}
.template-modal {
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  padding: 40px;
  box-shadow: 4px 4px 23px -6px rgba(0, 0, 0, 0.25);
}
.modal-close-container {
  align-self: flex-end;
}
.modal-content {
  padding: 33px 44px 33px 44px;
}
.modal-title {
  font-size: 24px;
  font-weight: 500;
}
.modal-select {
  margin-top: 20px;
  width: 80%;
}
.modal-error {
  color: #8e939c;
  margin-top: 16px;
  margin-left: 20px;
  font-weight: 400;
  font-size: 14px;
  margin-bottom: -55px;
}
.modal-input-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.modal-input {
  margin-top: 66px;
  width: 100%;
  font-size: 20px;
  font-weight: 500;
}

.modal-send-button {
  margin-top: 33px;
}

.batch-custom-polaris .Polaris-TextField__Input {
  margin-bottom: 35px;
  border-radius: 12px;
}

.action-bar {
  padding-top: 10px;
}
