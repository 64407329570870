.activity-feed {
  display: flex;
  flex-direction: column;
  padding: 29px 33px 29px 33px;
  overflow: auto;
}

.activity-feed-title {
  font-size: 24px;
  font-family: Helvetica, sans-serif;
  line-height: 36px;
  color: #000000;
}

.activity-feed-timeline {
  margin-left: 32px;
}

.vertical-timeline-element-content {
  padding: 0px !important;
}

.activity-feed-item {
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  background-color: #f3f3f3;
  color: #000000;
  padding: 9px 12px 9px 12px;
}

.activity-feed-icon {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.activity-feed-item-title {
  font-size: 16px !important;
  font-family: Helvetica, sans-serif;
  color: #000000;
  font-weight: 700 !important;
  margin: 0px !important;
}

.activity-feed-item-subtitle {
  font-size: 16px !important;
  font-family: Helvetica, sans-serif;
  color: #000000 !important;
  margin: 0px !important;
  white-space: pre-wrap;
}

.activity-feed-item-date {
  font-size: 12px !important;
  font-family: Helvetica, sans-serif;
  color: #969696 !important;
  padding-left: 4px;
}

.loading {
  padding-top: 20px;
}

.activity-feed-item-container {
  margin-top: 30px !important;
  margin-bottom: 40px !important;
}

.activity-feed-date-container {
  margin-top: 50px !important;
  margin-bottom: 0px !important;
}

.activity-feed-date-title {
  font-size: 20px !important;
  font-family: Helvetica, sans-serif;
  color: #000000;
  font-weight: 700 !important;
  margin: 0px !important;
}

.vertical-timeline-element-date {
  display: none !important;
}
