.overview {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  padding: 20px;
  background-color: #ffffff;
}

.activity-feed-container {
  width: 67%;
}

.stats-container {
  margin-left: 20px;
  width: 33%;
}

.send-assessment-button-container {
  margin-left: auto;
  margin-right: auto;
  width: 250px;
}
