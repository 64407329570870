.conversation {
  background-color: #f0f0f0;
  border-radius: 18px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  min-height: 400px;
}

.Modal .conversation{
  height: 100%;
}

.conversation-messages {
  overflow-y: auto;
  overflow-x: hidden;
  flex-grow: 1;
  display: flex;
  flex-direction: column-reverse;
  max-height: calc(60vh - 148px);
}

.Modal .conversation-messages{
  max-height: none;
}

.conversation-action-bar-container {
  width: 100%;
  padding: 15px;
  padding-top: 0px;
  padding-bottom: 10px;
}

.send-message-container {
  display: flex;
  flex-direction: column;
  width: "100%";
  margin-top: 15px;
}

.mentions-input-container {
  flex: 1;
  display: flex;
  flex-direction: row;
  width: 100%;
  min-height: 36px;
}
.send-message-input {
  width: 100%;
  min-height: 36px;
}

.conversation-polaris-input .Polaris-TextField__Input {
  background-color: #ffffff;
  border-radius: 12px;
}

.conversation-polaris-input .Polaris-TextField__Suffix {
  background-color: #ffffff;
  border-radius: 12px;
}

.conversation-polaris-input .Polaris-TextField__Backdrop {
  background-color: #ffffff;
  border-radius: 12px;
}

.internal-message-note {
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  padding-top: 5px;
  color: #8d2995;
}

.readonly-message-info {
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  padding: 15px;
  padding-top: 0px;
  color: #2562d8;
}

.conversation-input {
  min-height: 36px;
  max-height: 30vh;
}

.conversation-input__input {
  background-color: #ffffff !important;
  border-radius: 12px;
  border: none;
  padding: 5px 12px;
  line-height: 24px;
  overflow-y: auto !important;
}

.conversation-input__suggestions {
  background-color: #ffffff !important;
  border: 1px solid #bdbdbd;
  border-radius: 12px;
  min-width: 300px !important;
  padding: 15px;
}

.conversation-input__suggestions__item {
  border-bottom: 1px solid #cbcbcb;
  line-height: 40px;
  padding-left: 54px;
}

.conversation-input__suggestions__item:hover {
  background-color: #eeccf1;
}

.message-note-container {
  background-color: #e0e0e0;
  padding: 7px 14px 7px 14px;
  font-family: Helvetica;
}

.message-note-header-row {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
}
.message-note-header {
  font-weight: 700;
  padding-bottom: 5;
  padding-right: 5px;
}

.message-note {
  font-weight: 400;
}

