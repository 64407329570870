.invitesButton {
  border-radius: 20px;
  padding: 4px 8px 4px 8px;
  height: 70%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
.resendButton {
  background-color: #49a2ff;
  color: #ffffff;
  width: 54%;
  cursor: pointer;
  min-width: 130px;
}
.deleteButton {
  background-color: #e3e7ea;
  color: #131313;
  width: 50%;
  min-width: 100px;
  cursor: pointer;
}
.Modal-overlay {
  position: fixed;
  z-index: 90;
  background: rgba(0, 0, 0, 0.6);
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  animation: 0.3s cubic-bezier(0.22, 0.61, 0.36, 1) 1 fadeIn;
}
.accTypeLabel {
  font-size: 12px;
}
