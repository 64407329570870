.messages {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-grow: 1;
  width: 100%;
  padding: 30px;
  padding-top: 8px;
  padding-bottom: 4px;
  overflow-y: auto;
  background-color: white;
}

.contacts-list-container {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.conversations-list {
  font-weight: 700;
  font-size: 24px;
  font-family: Helvetica, sans-serif;
  padding-top: 17px;
  padding-bottom: 17px;
  border-bottom: 1px solid #cbcbcb;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.direct-message-line-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 20px;
  border-bottom: 1px solid #cbcbcb;
}

.line-item-clickable {
  cursor: pointer;
}

.selected {
  background-color: #e5eeff;
}

.direct-message-icon {
  width: 30px;
  height: 30px;
}

.direct-message-label {
  font-weight: 700;
  font-size: 16px;
  font-family: Helvetica, sans-serif;
  flex-grow: 1;
  margin-left: 16px;
}

.direct-message-unread {
  width: 20px;
  height: 20px;
  border-radius: 15px;
  background-color: #38d4de;
  justify-self: flex-end;
}

.conversation-container {
  flex: 2;
  flex-direction: column;
  padding-left: 30px;
  padding-bottom: 20px;
  height: 100%;
}

.direct-messages-empty {
  margin-top: 16px;
  font-size: 16px;
  font-family: Helvetica, sans-serif;
  font-style: italic;
}

.conversation-component-container {
  padding-top: 14px;
  padding-bottom: 14px;
}

.care-team-title-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-left: 15px;
}

.care-team-row-title {
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
}

.care-team-row-subtitle {
  font-family: "Helvetica";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 156%;

  color: #999999;
}

.linked-ctm-icon {
  width: 24px;
  height: 24px;
}
