#goalReflectionContainer {
  margin: 20px;
  border-bottom: 1px solid #dbdbdb;
  padding-bottom: 30px;
}
#achievedDate {
  color: #c5c5c5;
  font-size: 10px;
  margin-bottom: -6px;
}
.feedbackContainer {
  padding-top: 18px;
  padding-left: 0;
  font-size: 12px;
}
#reflectionLabel {
  margin-top: 30px;
  margin-bottom: 6px;
  font-size: 12px;
}
#reflection {
  font-size: 20px;
  overflow-wrap: anywhere;
}
.reflectionsEmoji {
  font-size: 24px;
}
#noReflections {
  padding: 40px;
}
.offsetThumbsDown {
  position: relative;
  top: 4px;
}
.reflectionsLabel {
  font-size: 14px;
  margin-right: 8px;
}
.reflectionsText {
  font-size: 12px;
  color: #2562d8;
  font-weight: 700;
  margin-left: 8px;
}
