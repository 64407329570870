:root {
  --font-family: 
    // Safari for OS X and iOS (San Francisco) -apple-system,
    // Chrome < 56 for OS X (San Francisco) BlinkMacSystemFont,
    // Windows "Segoe UI",
    // Android "Roboto",
    // Basic web fallback "Helvetica Neue", Arial, sans-serif,
    // Emoji fonts "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol" !default;

  /* u - unit */
  --spacing-1u: 4px;
  --spacing-2u: 8px;
  --spacing-3u: 12px;
  --spacing-4u: 16px;

  /* s - space */
  --spacing-s: 20px;
  --spacing-m: 40px;
  --spacing-l: 80px;
  --spacing-xl: 160px;

  --color-accent: #3dc1d3;
}

:focus {
  outline: none;
}

:focus-visible {
  /* stylelint-disable-line */
  outline: 3px solid var(--color-accent);
}

/*
  Suppress the focus outline on elements that cannot be accessed via keyboard.
  This prevents an unwanted focus outline from appearing around elements that
  might still respond to pointer events. See: https://github.com/suitcss/base
*/
[tabindex="-1"]:focus {
  outline: 0 !important;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  margin: 0;
  background: rgb(244, 245, 250);
}
