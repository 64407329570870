.ActiveGoals {
  overflow: auto;
}

.ActiveGoal {
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid #ececec;
}

.ActiveGoal-content {
  padding: 20px;
}

.ActiveGoal-container {
  display: flex;
  flex-direction: row;
}

.ExpirationHeader {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  padding: 22px;
  background-color: #45c1d2;
}

.ExpirationHeader-label {
  color: #ffffff;
  font-size: 16px;
  font-weight: 500;
  margin-right: 20px;
}

.ExpirationHeader-buttons {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.OtherOwnerGoal-label {
  display: flex;
  height: 30px;
  align-items: center;
  color: #3dc1d3;
  font-weight: 600;
}

.ActiveGoal:last-of-type {
  border: none;
}

.ActiveGoal-details {
  flex: 1;
  padding-right: 20px;
}

.ActiveGoal-title {
  font-size: 16px;
  font-weight: 600;
  line-height: 1.5;
  color: #222222;
}

.ActiveGoal-calendar {
  flex: 1;
  padding-right: 40px;
  padding-bottom: 20px;
}

.ActiveGoal-meta {
  font-size: 14px;
  line-height: 1.7;
  color: #222222;
  margin-bottom: 40px;
}

.noGoals {
  width: 100%;
  text-align: center;
  padding: 50px;
  font-weight: bold;
}

.assigned-header {
  height: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.goal-name-and-mark-aAchieved-header {
  min-height: 40px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.due-header {
  margin-bottom: 20px;
}

.mark-achieved-button {
  padding-left: 5px;
  padding-right: 20px;
  white-space: nowrap;
}
.view-photos-button {
  padding-top: 20px;
  padding-left: 5px;
  padding-right: 5px;
  padding-bottom: 0px;
  white-space: nowrap;
  width: 200px;
}
