.conversation-header {
  min-height: 70px;
  width: 100%;
  border-top-left-radius: 18px;
  border-top-right-radius: 18px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  overflow: hidden;
  padding-top: 10px;
}

.conversation-header-purple {
  background-color: #8d2995;
}

.conversation-header-manablue {
  background-color: #2562d8;
}

.conversation-header-text {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  justify-self: center;
  padding-left: 5px;
  padding-right: 5px;
  text-align: center;
  padding-bottom: 5px;
}

.conversation-title {
  font-weight: 700;
  font-size: 24px;
  font-family: Helvetica, sans-serif;
  margin-bottom: 4px;
  word-break: break-all;
}

.conversation-subtitle {
  font-size: 16px;
  font-family: Helvetica, sans-serif;
  margin-top: 4px;
}

.conversation-header-leave-button {
  align-self: flex-end;
  position: relative;
  right: 15px;
  top: -3px;
  height: 0px;
  cursor: pointer;
}

.conversation-family-header {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #e0e0e0;
  overflow-y: hidden;
}

.family-text {
  font-size: 12px;
  font-family: Helvetica, sans-serif;
  font-weight: 500;
  color: #1a2579;
}
