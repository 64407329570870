.Connection {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: var(--spacing-s);
  box-sizing: border-box;
  background: rgb(244, 245, 250);
  background-image: url("../assets/login-bg.png");
  background-position: center bottom;
  background-size: 80%;
  background-repeat: no-repeat;
}

.Connection-form {
  width: 380px;
  background: white;
  border-radius: 4px;
  line-height: 40px;
  padding: 40px;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.1);
}

.Connection-header {
  font-size: 40px;
  font-weight: bold;
  text-align: center;
  padding: 20px 50px 20px 50px;
}

.connection-parent-email {
  margin-top: 0px !important;
}

.Connection-subtitle {
  text-align: center;
  font-size: 14px;
  line-height: 1.4;
  color: #575f67;
  margin-top: 25px;
  margin-bottom: 50px;
  font-weight: 600;
}

.Connection-label {
  text-align: center;
  font-size: 14px;
  line-height: 1.4;
  color: #575f67;
  margin-top: 25px;
  margin-bottom: 15px;
  font-weight: 600;
}

.Margin-bottom {
  margin-bottom: 25px;
}

#Connection-submit {
  margin-top: 40px;
}

.Modal-overlay {
  position: fixed;
  z-index: 90;
  background: rgba(0, 0, 0, 0.6);
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  animation: 0.3s cubic-bezier(0.22, 0.61, 0.36, 1) 1 fadeIn;
}
