.Sidebar {
  width: 280px;
  min-height: 100vh;
  background: #001429;
  z-index: 1;
}

.Sidebar-wrapper {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  width: 280px;
  overflow: auto;
}

.Sidebar.Sidebar--mini,
.Sidebar--mini .Sidebar-wrapper {
  width: 50px;
}

.Sidebar-content {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 100%;
  position: fixed;
  color: white;
  position: relative;
}

.Sidebar-logo,
.Sidebar-logoMini {
  background-image: url("../assets/manatee-logo-white.png");
  background-size: contain;
  background-repeat: no-repeat;
  font-size: 16px;
  font-weight: 900;
  padding: 30px;
}

.Sidebar-logo {
  padding: 45px;
  margin: 5px 0 5px 35px;
}

.Sidebar-logoMini {
  padding: 20px 0;
  margin: 5px;
}

.Sidebar-profile {
  display: flex;
  background: #007ace;
  flex-direction: row;
  padding: 13px 30px;
}

.Sidebar--mini .Sidebar-profile {
  display: none;
}

.Sidebar-avatar {
  width: 48px;
  height: 48px;
  background-size: cover;
  background-position: center center;
  border-radius: 100px;
  margin-right: 15px;
}

.Sidebar--mini .Sidebar-avatar {
  margin-right: 0;
}

.Sidebar-name {
  font-size: 14px;
  font-weight: 600;
  margin-top: 2px;
}

.Sidebar-location {
  font-size: 12px;
  font-weight: 600;
  color: rgba(255, 255, 255, 0.6);
}

.Sidebar-nav {
  display: flex;
  flex-direction: column;
  min-height: 0;
}

.Sidebar-flex-narrow {
  flex: 0.5;
}

.Sidebar-flex-wide {
  flex: 1;
}

.Sidebar-spacer {
  margin-top: 30px;
}

.Sidebar--mini .Sidebar-spacer {
  opacity: 0;
}

.Sidebar-link {
  cursor: pointer;
  color: white;
  text-decoration: none;
  font-size: 14px;
  font-weight: 600;
  transition: opacity 0.3s;
  padding: 10px 26px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border-radius: 12px;
  border-left: 4px solid transparent;
  margin: 0 15px;
}

.Sidebar--mini .Sidebar-link {
  padding: 10px 0px;
  margin: 0 10px;
}

.Sidebar-link:hover {
  opacity: 0.8;
}

.Sidebar-link.Sidebar--active {
  opacity: 1;
  background: #007ace;
}

.Sidebar-close {
  margin: 12px;
}

.Sidebar-collapse,
.Sidebar-expand {
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  color: white;
  text-decoration: none;
  transition: opacity 0.3s;
  padding: 20px;
}

.Sidebar-f {
  display: block;
  margin-right: 15px;
  margin-top: 5px;
  margin-bottom: 5px;
}

.Sidebar-m {
  display: none;
  margin-top: 5px;
  margin-bottom: 5px;
}

.Sidebar--mini .Sidebar-f {
  display: none;
}

.Sidebar--mini .Sidebar-m {
  display: block;
}

.counter {
  border-radius: 14px;
  height: 20px;
  width: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
}

.counterActive {
  background-color: #ffffff;
  color: #007ace;
}

.counterInactive {
  background-color: #007ace;
  color: #ffffff;
}

.Sidebar-menu-item {
  display: flex;
  flex-direction: row;
  align-items: center;
}
