.header-container {
  display: flex;
  flex-direction: row;
  background: #f5f5f6;
  padding: 50px 50px 20px 50px;
  justify-content: space-between;
  align-items: center;
}

.header-container-narrow {
  display: flex;
  flex-direction: column;
  background: #f5f5f6;
  padding: 50px 50px 20px 50px;
  justify-content: space-between;
  align-items: center;
  z-index: 0;
}

.header-text {
  flex: 1;
  color: #000000;
  font-family: Helvetica;
  font-weight: 700;
  font-size: 30px;
  line-height: 32px;
}

.header-text-narrow {
  flex: 5;
  color: #000000;
  font-family: Helvetica;
  font-weight: 700;
  font-size: 30px;
  line-height: 32px;
}
