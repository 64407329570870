.Invite {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: var(--spacing-s);
  box-sizing: border-box;
  background: rgb(244, 245, 250);
  background-image: url("../assets/login-bg.png");
  background-position: center bottom;
  background-size: 80%;
  background-repeat: no-repeat;
}

.Invite-form {
  width: 380px;
  background: white;
  border-radius: 4px;
  padding: 40px;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.1);
}

.back-button {
  border-radius: 10px;
  background-color: #bfdef2;
  height: 32px;
  width: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.invite-first-last-name {
  margin-top: 0px !important;
}

.back-icon {
  color: "#007ACB";
}

.Invite-label {
  text-align: center;
  font-size: 14px;
  line-height: 1.4;
  color: #575f67;
  margin-top: 25px;
  margin-bottom: 15px;
  font-weight: 600;
}

.Invite-label a {
  color: #575f67;
}

.Invite-label-second {
  text-align: center;
  font-size: 14px;
  line-height: 1.4;
  color: #575f67;
  margin-bottom: 35px;
}

.Invite-label-second a {
  color: #575f67;
}

#Invite-submit {
  margin-top: 40px;
}

.Invite .Polaris-TextField__Input.Polaris-TextField__Input {
  text-align: center;
}

.Invite .Polaris-TextField.Polaris-TextField {
  min-height: 48px;
}

.Invite .Polaris-Button.Polaris-Button {
  height: 48px;
}
