.page-container {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  min-height: 100vh;
  background-size: 100vw 135vh;
}

.message-title {
  font-weight: 600;
  font-size: 26px;
  line-height: 40px;
  padding: 20px;
  text-align: center;
}
