.ToggleActive {
  background-color: #fdfdfd;
  color: #3da8d3;
  flex-basis: 0;
  flex-grow: 1;
  border-radius: 18px;
  border: none;
  font-family: Avenir Next;
  font-size: 20px;
  line-height: 27px;
  font-weight: 600;
}
.ToggleInactive {
  border: none;
  background-color: #e4e6ea;
  color: #a6a6a6;
  flex-basis: 0;
  flex-grow: 1;
  font-family: Avenir Next;
  font-weight: 600;
  font-size: 20px;
  line-height: 27px;
}
.ToggleContainer {
  border: none;
  background-color: #e4e6ea;
  display: flex;
  flex-direction: row;
  padding: 5px;
  border-radius: 25px;
  height: 40px;
}
