.GoalCard-parentCard {
  border-bottom: 10px;
}

.GoalCard-parentName {
  font-family: Avenir Next;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 33px;
  display: flex;
  align-items: center;
  color: #000000;
  padding-top: 20px;
  padding-left: 20px;
  background-color: white;
}

.GoalCard-content {
  background-color: white;
  border: none;
  display: grid;
  padding: 20px;
  grid-gap: 20px;
  grid-template:
    "a b"
    "c d" / 1fr 1fr;
}

.GoalCard-statsCard {
  grid-area: b;
}

.GoalCard-goalsCard {
  grid-area: a;
}

.GoalCard-goalReflections-title {
  height: 40px;
  font-family: Avenir Next;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 33px;
  display: flex;
  align-items: center;
}
