.CompletedGoals {
  overflow: auto;
}

.Goal-container {
  padding-left: 40px;
  padding-right: 40px;
}
.Goal-content-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-bottom: 40px;
  border-bottom: 1px solid #dbdbdb;
}
.Goal-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.Goal-header {
  padding-top: 2px;
  padding-left: 5px;
  padding-right: 5px;
}
.Goal-header-image {
  width: 100%;
  align-self: center;
}
.Goal-image {
  margin-left: 30px;
  margin-right: 30px;
  height: 90px;
  width: 90px;
}
.Goal-title {
  font-family: Avenir Next;
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 30px;
  color: #3da8d3;
}
.Goal-date-container {
  margin-top: 5px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  background: #c6ecf4;
  border-radius: 3.13725px;
  padding: 7px;
}
.Goal-calendar-image {
  margin-right: 5px;
  height: 15px;
  width: 15px;
}
.Goal-date {
  font-family: Avenir Next;
  font-style: normal;
  font-weight: 500;
  font-size: 10.9804px;
  line-height: 15px;
  display: flex;
  align-items: center;

  color: #3da8d3;
}
.Goal-name {
  padding-top: 13px;
  font-family: Avenir Next;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;

  color: #000000;
}

.noGoals {
  width: 100%;
  text-align: center;
  padding: 50px;
  font-weight: bold;
}
