.Spinner {
  width: 80px;
  height: 80px;

  border: 2px solid #eaeaea;
  border-top: 5px solid #007ace;
  border-radius: 100%;

  position: absolute;
  top:0;
  bottom:0;
  left:0;
  right: 0;
  margin: 120px calc(50% - 40px);

  opacity: 0;
  animation: 0s linear 0s forwards spinner-fade-in, 1s linear infinite spinner;
}

.Spinner-inline {
  width: 60px;
  height: 60px;

  border: 2px solid #eaeaea;
  border-top: 5px solid #007ace;
  border-radius: 100%;

  justify-self: center;
  align-self: center;

  opacity: 0;
  animation: 0s linear 0s forwards spinner-fade-in, 1s linear infinite spinner;
}

@keyframes spinner-fade-in {
  from {
    opacity: 0;
  } to {
    opacity: 1;
  }
}

@keyframes spinner {
  from {
    transform: rotate(0deg);
  } to {
    transform: rotate(360deg);
  }
}
