.Chat {
  padding: 0 20px 20px 20px;
}

.Chat--large {
  position: fixed;
  top: 71px;
  bottom: 0;
  overflow: scroll;
}

.Chat-day {
  font-size: 12px;
  font-weight: 600;
  line-height: 1.33;
  color: #131313;
  margin: 20px 0;
}

.Chat-bubble {
  position: relative;
  border-radius: 10px;
  background-color: rgba(145, 158, 171, 0.1);
  margin-left: 20px;
  margin-bottom: 8px;
  padding: 20px 20px 20px 44px;
}

.Chat-bubble.Chat--compact {
  padding: 10px 20px 10px 44px;
}

.Chat-user {
  font-size: 16px;
  font-weight: 600;
  line-height: 1.5;
  color: #131313;
}

.Chat-message {
  font-size: 14 px;
  line-height: 1.4;
  color: #131313;
}

.Chat-avatar {
  width: 60px;
  height: 60px;
  border-radius: 60px;
  background-size: cover;
  background-position: center center;
  background-color: #007ace;
  border: 6px solid white;
  position: absolute;
  left: -30px;
  top: 0px;
}

.Chat-timestamp {
  opacity: 0.7;
  font-size: 12px;
  line-height: 1.33;
  color: #131313;
  position: absolute;
  right: 20px;
  top: 20px;
}
