@import "mixins";
$MANA_BLUE: #2562d8;
$MANA_BLUE_LIGHT: #bbd2ff;
$MANA_BLUE_DARK: #1a2579;

@keyframes spinAround {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

.appointment-time-picker-empty-state {
  color: #212328;
  font-weight: 600;
  font-family: Helvetica;
}

.availability-on-header {
  text-align: center;
}

.availability-on-header-date {
  color: #1a2579;
  font-family: Helvetica;
  font-size: 24px;
  font-weight: 800;
  line-height: 38px;
}

.availability-on-header-timezone {
  color: #636c8c;
  font-family: Helvetica, sans-serif;
  font-size: 11px;
  font-weight: 600;
  line-height: 16px;
}

.areas-of-day-flexbox {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin-top: 16px;
  margin-bottom: 30px;
}

.available-slot {
  border: #bfc4cc 1px solid;
  background: rgb(255, 255, 255);
  border-radius: 10px;
  height: 50px;
  width: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $MANA_BLUE;
  font-weight: 800;
  font-family: Helvetica, sans-serif;
  font-size: 18px;
  line-height: 16px;
  text-align: center;
  margin-bottom: 5px;
  margin-top: 5px;
  margin-right: 5px;
  margin-left: 5px;
  cursor: pointer;

  &:hover {
    background-color: #2562d8;
    color: white;
  }
}

.available-slot.active-slot {
  background-color: $MANA_BLUE_LIGHT;
  &:hover {
    color: $MANA_BLUE_DARK;
    border-color: $MANA_BLUE_DARK;
  }
}

.available-slots-for-day {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.appointment-date-picker-container {
  .react-datepicker {
    background-color: transparent;
    min-height: 250px;
    border-radius: 0;
    background-color: #ffffff;
    border: none;
    margin-bottom: 30px;
  }

  .react-datepicker__month-container {
    .react-datepicker__month {
      margin: 0;
    }
  }

  // chevron buttons start
  .react-datepicker__navigation {
    line-height: unset;
    width: unset;
  }

  .react-datepicker__navigation--previous::before,
  .react-datepicker__navigation--next::before {
    content: "";
    color: #2562d8;
    width: 14px;
    height: 14px;
    border-style: solid;
    border-width: 4px 4px 0 0;
    position: absolute;
    z-index: 100;
    top: 6px;
  }

  .react-datepicker__navigation--previous,
  .react-datepicker__navigation--next {
    padding-bottom: 2px;
    width: 30px;
    height: 30px;
  }
  .react-datepicker__navigation--next {
    right: 0px;
  }
  .react-datepicker__navigation--previous::before {
    transform: rotate(-135deg);
    left: 15px;
  }

  .react-datepicker__navigation--next::before {
    transform: rotate(45deg);
    right: 15px;
  }

  // chevron buttons end

  .react-datepicker__header {
    background-color: inherit;
    border: 0px;
  }

  .react-datepicker__current-month {
    color: #1a2579;
    font-family: Helvetica;
    font-size: 15px;
    font-weight: 700;
    line-height: 21px;
    text-align: center;
  }

  .react-datepicker__day-name {
    color: #8e939c;
    font-family: Helvetica;
    font-size: 14px;
    font-weight: 700;
    text-align: center;
  }

  .react-datepicker__day-names {
    margin-bottom: 0%;
    margin-top: 22px;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
  }

  .react-datepicker__day {
    color: #383b43;
    font-family: Helvetica;
    font-size: 16px;
    font-weight: 500;
    text-align: center;
    padding-top: 1px;
    margin-right: 5px;
    margin-left: 5px;
    margin-bottom: 6px;
    border-radius: 50%;
    width: 38px;
    height: 38px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }

  .react-datepicker__day--today {
    color: #2562d8;
    font-family: Helvetica;
    font-weight: 900;
  }
  .react-datepicker__day:hover {
    color: #ffffff;
    background-color: #2562d8;
    font-family: Helvetica;
    font-weight: 900;
    border-radius: 50%;
  }

  .react-datepicker__day--highlighted {
    background-color: #e5eeff;
  }

  .react-datepicker__day--highlighted:hover {
    background-color: #2562d8;
  }

  .react-datepicker__day--disabled {
    color: #dadada;
    background-color: inherit;
    &:hover {
      color: #dadada;
      background-color: rgba(0, 0, 0, 0);
    }
  }

  .react-datepicker__day--selected {
    background-color: #1a2579;
    color: #fff;
  }

  .react-datepicker__day--outside-month {
    visibility: hidden;
  }
}

.appointment-date-picker .react-datepicker {
  background-color: transparent;
  width: 341px;
  min-height: 250px;
  border-radius: 0;
  background-color: #fff;
  border: none;
  margin-top: 10px;
  margin-bottom: 20px;
  margin-left: 10px;
  margin-right: 10px;
}

.control.is-loading::after,
.loader {
  -webkit-animation: spinAround 500ms infinite linear;
  animation: spinAround 500ms infinite linear;
  border: 2px solid #dbdbdb;
  border-radius: 290486px;
  border-right-color: transparent;
  border-top-color: transparent;
  content: "";
  display: block;
  height: 1em;
  position: relative;
  width: 1em;
}
